.glowBadge {
  animation: glow 2s infinite !important;
}
@keyframes glow {
    0% {
      box-shadow: 0 0 10px rgba(235, 68, 68, 0.6);
    }
    50% {
      box-shadow: 0 0 30px rgba(235, 68, 68, 0.9);
    }
    100% {
      box-shadow: 0 0 10px rgba(235, 68, 68, 0.6);
    }
  }