.futuristic-menu-rtl {
    position: fixed;
    bottom: 20px;
    left: 630px;
    z-index: 999;
    transition: transform 0.5s;
    transform: translateX(0); 
  }
  

.futuristic-menu {
  
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 999;
    transition: transform 0.5s;
    transform: translateX(0); 
  }

 


  .futuristic-menu.open {
    transform: translateX(-100%); 
  }

  .menu-button {

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
  }
  .menu-button-rtl {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }
  

  .bar.glow {
    box-shadow: 0 0 20px rgb(249,58,92);
  }

  .bar {
    position: relative;
    width: 65px;
    padding: 5px;
    text-align: center;
    border-radius: 40px;
    font-size: 10px;
    height: fit-content;
    background-color: #fdd005;
    margin-bottom: -2.3rem ; 
    margin-right: 0.7rem;
    transition: 0.2s;
  }

  .menu-button.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-button.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-button.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .menu-items {
   
    display: flex;
    flex-direction: row; 
    align-items: center; 
    transform: translateX(0);
    transition: transform 0.5s;
   
  }

  .menu-item {
    margin: 0 10px; 
    background-color: #333;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 0 3px rgb(249,58,92);;
  }

  .menu-items-rtl {
    display: flex;
    flex-direction: row-reverse; 
    align-items: center;
    transform: translateX(0);
    transition: transform 0.5s;
  }
  

  .menu-item:hover {
    background-color: #555;
  }

  .menu-items {
    transform: translateX(100%); 
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998; 
    display: none;
    transition: opacity 0.3s ease;
  }
  
  .overlay.visible {
    display: block;
    opacity: 1;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .futuristic-menu, .futuristic-menu-rtl {
        bottom: 20px;
        left: 7px;
        right: 7px;
    }

    .bar {
        width: 55px;
        font-size: 9px;
        margin-right: 1.5rem;
    }

    .menu-item {
        margin: 0 7px; 
        padding: 7px 14px;
        font-size: 14px;
    }
}

  @media screen and (max-width: 768px) {
    .futuristic-menu, .futuristic-menu-rtl {
        bottom: 20px;
        left: 10px;
        right: 10px;
    }

    .bar {
        width: 50px;
        font-size: 8px;
        
    }

    .menu-item {
        margin: 0 5px; 
        padding: 6px 12px;
        font-size: 10px;
    }
}








