/* Custom styles for the scrollable sections */
.scrollable-details {
    margin-bottom: 1rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    max-height: 165px;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 1rem;
  }

  .scrollable-details-customer {
    margin-bottom: 1rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    max-height: 200px;
   
    padding: 1rem;
  }
  
  /* Styling for the details headings */
  .details-heading {
    margin-left: 0.3rem;
    margin-bottom: 0.5rem;
  }
  
  /* Styling for individual items within the details */
  .details-item {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  .modal-heading{
    font-weight: 700;
  }
  
  /* Customize the scrollbar style */
  .scrollable-details::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-details::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .scrollable-details::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px;
  }
/*   

  @keyframes glow {
    0%, 100% {
      box-shadow: 0 0 0px #00ff00;
    }
    60% {
      transform: scale(1.09);
      box-shadow: 0 0 20px #556EE6;
    }
  } */
  
  .glow-effect {
    animation: glow 1s infinite;
    cursor: pointer;
    
  }

  .scroll-container {
    gap: 10px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  }


  .game {
    display: inline-block;
    margin-right: 7px;
    position: relative;
  }
  
  .game-image {
    margin: auto;
    max-width: 80px;
    border-radius: 35%;
    height: 80px;
    margin-bottom: 8px;
  }
  
  .table-number-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    border-radius: 35%;
    margin: auto;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    transition: opacity 0.3s;
  }
  
  .table-number-overlay p {
    margin: 0;
    font-weight: 600;
    color: black;
  }
  
  .game:hover .table-number-overlay {
    opacity: 1;
  }
  .game:hover .table-number-overlay p  {
    color:#F93A5C
  }
  .scroll-container::-webkit-scrollbar {
    height: 2px;
   visibility: hidden;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #ff7a72;
    
    /* visibility: hidden; */
   
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    /* visibility: hidden; */
  }

  /* For Table Status New Page */
  .game-image-ts {
    margin: auto;
    max-width: 90px;
    border-radius: 35%;
    height: 90px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .box {
    /* box-shadow: 5px 5px 5px 5px #C1C2C4; */
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }


  .game-image-ts:hover{
    opacity :  0.4 ;
  }

  .scroll-container-ts::-webkit-scrollbar {
    width: 7px;
  }

.scroll-container-ts::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.scroll-container-ts::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 15px; 
}

.scroll-container-ts::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

 



.game-container {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
 
}

.game-container:hover {

  cursor: pointer;
  color: #F93A5C;
}

.game-image-ds {
  height: 110px;
  width: 100px;

}


.game-image-content {
  width: 90px;
  border-radius: 30px;
  margin: auto;
}



.game-name {
  text-align: center;
  font-size: 13px;
  /* margin-top: 5px; */
}

.selected-game {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  border: 4px dotted #F93A5C;
}

.selected-name {
  text-align: center;
  /* font-size: 13px; */
  color: #F93A5C
}

 /* Dashbaord booking  */
 .checkbox-wrapper-25 input[type="checkbox"] {
  background-image: -webkit-linear-gradient(hsla(0,0%,0%,.1), hsla(0,0%,100%,.1)),
                    -webkit-linear-gradient(left, #f66 50%, #6cf 50%);
  background-size: 100% 100%, 200% 100%;
  background-position: 0 0, 15px 0;
  border-radius: 25px;
  box-shadow: inset 0 1px 4px hsla(0,0%,0%,.5),
              inset 0 0 10px hsla(0,0%,0%,.5),
              0 0 0 1px hsla(0,0%,0%,.1),
              0 -1px 2px 2px hsla(0,0%,0%,.25),
              0 2px 2px 2px hsla(0,0%,100%,.75);
  cursor: pointer;
  height: 15px;
  padding-right: 25px;
  width: 35px;
  -webkit-appearance: none;
  -webkit-transition: .25s;
}
.checkbox-wrapper-25 input[type="checkbox"]:after {
  background-color: #eee;
  background-image: -webkit-linear-gradient(hsla(0,0%,100%,.1), hsla(0,0%,0%,.1));
  border-radius: 25px;
  box-shadow: inset 0 1px 1px 1px hsla(0,0%,100%,1),
              inset 0 -1px 1px 1px hsla(0,0%,0%,.25),
              0 1px 3px 1px hsla(0,0%,0%,.5),
              0 0 2px hsla(0,0%,0%,.25);
  content: '';
  display: block;
  height: 15px;
  width: 20px;
}
.checkbox-wrapper-25 input[type="checkbox"]:checked {
  background-position: 0 0, 35px 0;
  padding-left: 25px;
  padding-right: 0;
}




  