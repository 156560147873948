
.react-bootstrap-table th.sortable::after {
    content: '⬆⬇'; 
    color: whitesmoke; 
  }
  

  /* .react-bootstrap-table th.sortable::before {
    content: '\25bc'; 
    color: #ffe7a3; 
  }
   */