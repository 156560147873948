
.button-71 {

    background: #fdd005;
    width: 60px ; 
    height: 36px ;
    margin-top: 1.7rem;
    border: 0;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: flex; 
    justify-content: center;
    align-items: center;
    font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-size: 15px;
    font-weight: 500; 

   
    padding: 16px 21px; 
    position: relative;
    text-align: center;
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;

    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 30px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 1.5px;
    transition: all .3s;
    width: 90%;
  }
  

  
  .button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .5) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .button-71 {
      padding: 16px 34px;
    }
  } 


  
  