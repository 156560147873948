@media print {
    .table-responsive {
      overflow: visible !important;
    }
    
    .table {
      page-break-inside: auto;
    }
    
    .table tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    .table-footer {
      display: table-row-group;
    }
  }